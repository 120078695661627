import { PROPERTY_DATA_TYPE, TOO_OLD_SALE_PROPERTY_DATE } from "@share/const"
import type { PropertyCoreData } from "@share/entities/Property/PropertyCoreData"
import { format } from "date-fns"

import _ from "lodash"

const dataTypes = _.values(PROPERTY_DATA_TYPE)

export class Property implements PropertyCoreData, Locatable {
  id: string
  buildingName: string
  lat: number
  lng: number
  contractPrice: number
  exclusiveArea: number
  landArea: number
  deposit: number
  keyMoney: number
  regPrice: number
  floorPlanType: string
  floorPlanNumberOfRooms: number
  buildingArea: number
  aboveGroundFloorLevels: number
  floor: number
  prefectureName: string
  floorType: string
  roomBuildingFloor: string
  locationName: string
  locationName1: string
  locationName2: string
  town: string
  listingAgent: string
  listingAgentPhone: string
  constructionYearmonth: `${number}/${number}`
  updatedAt: number
  createdAt: number
  saleDays: number
  age: number
  unitAmount: number
  bicycleParking: boolean
  parking: boolean
  pet: string
  propertyType: PropertyType
  propertyClassification: string
  propertyId: string
  dataType: DataType
  status: undefined | null | 0 | "" | "sold" | "price_drop"
  tags: { name: string }[]
  imageUrls: string[]
  contractDate?: string
  title?: string
  description?: string
  isDeleted?: boolean
  jhSchoolId?: string
  eleSchoolId?: string
  stationId?: string
  separateToilet?: string
  saleAddedOn?: string
  resource?: string
  url?: string

  constructor(core: PropertyCoreData) {
    Object.assign(this, core)
  }

  //
  // core getters ここから
  //

  // get position() {
  //   return {
  //     lat: this.lat,
  //     lng: this.lng,
  //   }
  // }

  get contractYear() {
    return this.contractDate?.split?.("/")[0]
  }

  //
  // core getters ここまで
  //

  /**
   * propertyId をもとに id と dataType を取得する
   * @param {{ propertyId: string }} propertyId
   * @returns {{ id: string, dataType: string }} id と dataType
   */
  static getDataTypeAndIdFromPropertyId({ propertyId }) {
    const [dataType, id] = propertyId.split("-")
    if (!dataTypes.includes(dataType)) {
      throw new Error(`INVALID_DATA_TYPE ${dataType}`)
    }
    return { id, dataType }
  }

  /**
   * プロパティの ID
   */
  // get propertyId() {
  //   return `${this.dataType}-${this.id}`
  // }
  /**
   * 座標の文字列
   */
  get latLng() {
    return this.lat + "-" + this.lng
  }
  /**
   * 現在の価格のテキスト
   */
  get contractPriceText() {
    return this.contractPrice + "万円"
  }
  /**
   * 現在の単価
   */
  get contractUnitPrice() {
    // マンションの場合は専有面積、戸建・土地の場合は土地面積
    const baseArea = this.isApartment ? this.exclusiveArea : this.landArea
    return baseArea ? Math.floor(this.contractPrice / baseArea) : null
  }
  /**
   * 現在の単価のテキスト
   */
  get contractUnitPriceText() {
    return (this.contractUnitPrice || "--") + "万円/㎡"
  }
  /**
   * 登録時価格のテキスト
   */
  get regPriceText() {
    return this.regPrice + "万円"
  }
  /**
   * 間取りのテキスト
   */
  get floorPlanText() {
    if (this.floorPlanType === "ワンルーム") {
      return this.floorPlanType
    }
    return this.floorPlanNumberOfRooms && this.floorPlanType
      ? this.floorPlanNumberOfRooms + this.floorPlanType
      : "--"
  }
  /**
   * マンションの場合、専有面積のテキスト
   * 戸建て土地の場合、建物面積のテキスト
   */
  get areaText() {
    if (this.isApartment) {
      return this.exclusiveArea ? this.exclusiveArea + "㎡" : "--"
    }
    if (this.isHouse) {
      return this.buildingArea ? this.buildingArea + "㎡" : "--"
    }
    if (this.isLand) {
      return this.landAreaText
    }
    return "--"
  }
  /**
   * 土地面積のテキスト
   */
  get landAreaText() {
    if (this.isApartment) {
      return "--"
    }
    return this.landArea ? this.landArea + "㎡" : "--"
  }
  /**
   * マンションの場合、「所在階/総階数」のテキスト
   * 戸建の場合、総階数のテキスト
   * 土地の場合、"--"
   */
  get floorText() {
    if (this.isLand) {
      return "--"
    }
    if (this.isHouse) {
      return this.aboveGroundFloorLevels
        ? this.aboveGroundFloorLevels + "階立て"
        : "--"
    }
    return (
      (this.floor || "--") + "/" + (this.aboveGroundFloorLevels || "--") + "階"
    )
  }
  /**
   * 住所
   */
  get address() {
    return (
      (this.prefectureName || "") +
      (this.locationName1 || "") +
      (this.town || "")
    )
  }

  /**
   * 住所 + 物件名
   */
  get location() {
    return this.address + (this.buildingName || "")
  }

  /**
   * 掲載会社情報
   */
  get listingAgentInfo() {
    if (!this.listingAgent) {
      return ""
    }
    if (!this.listingAgentPhone) {
      return this.listingAgent
    }
    return `${this.listingAgent}(${this.listingAgentPhone})`
  }

  /**
   * 建築申請日に基づくグループ分け
   *
   * - 0 2000年基準（木造） 2000年6月1日〜
   * - 1 新耐震 1981年6月1日〜
   * - 2 旧耐震 〜1981年5月31日
   *
   * 実際には築年月から割り出すので、建築確認日から竣工までの期間を考慮して、上の日時に半年加えた値にしておく
   * 物件の類似度判定に用いる
   *
   * 建築確認日から竣工までの期間は、戸建の場合6ヶ月、マンションの場合1年半ほどだが、
   * このグループ分けは戸建の簡易類似度判定にのみ必要なので、半年加えるだけにする
   */
  get constructionGroupNum() {
    if (!this.constructionYearmonth) {
      return 2
    }
    const v = parseInt(this.constructionYearmonth.replace("/", ""))
    // FIXME: マジックナンバーはconstに集めたい
    if (200100 < v) {
      return 0
    } else if (198200 < v) {
      return 1
    } else {
      return 2
    }
  }

  /**
   * 更新日時のテキスト
   */
  get updatedAtText() {
    return new Date(this.updatedAt).toLocaleDateString()
  }
  /**
   * 更新日までの日数
   */
  get relativeUpdatedAt() {
    const diff = Date.now() - this.updatedAt
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24))
    return days
  }
  /**
   * 更新日までの日数のテキスト ◯日
   */
  get relativeUpdatedAtText() {
    return this.relativeUpdatedAt + "日"
  }
  /**
   * 作成日時のテキスト
   */
  get createdAtText() {
    return new Date(this.createdAt).toLocaleDateString()
  }
  /**
   * 作成日までの日数
   */
  get relativeCreatedAt() {
    const diff = Date.now() - this.createdAt
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24))
    return days
  }
  /**
   * 作成日までの日数のテキスト ◯日
   */
  get relativeCreatedAtText() {
    return this.relativeCreatedAt + "日"
  }
  /**
   * 販売日数のテキスト
   */
  get saleDaysText() {
    return (this.saleDays || "--") + "日"
  }
  /**
   * 築年数のテキスト
   */
  get ageText() {
    return (this.age || "--") + "年"
  }
  /**
   * 総戸数のテキスト
   */
  get unitAmountText() {
    return this.unitAmount ? this.unitAmount + "戸" : "--"
  }
  /**
   * 駐輪場のテキスト
   */
  get bicycleParkingText() {
    return this.bicycleParking ? "あり" : "--"
  }
  /**
   * 駐車場のテキスト
   */
  get parkingText() {
    return this.parking ? "あり" : "--"
  }
  /**
   * ペットのテキスト
   */
  get petText() {
    return this.pet ? "相談" : "--"
  }
  /**
   * マンションかどうか
   */
  get isApartment() {
    return this.propertyType === "apartment"
  }
  /**
   * 戸建てまたは土地かどうか
   */
  get isHouseOrLand() {
    return this.propertyType === "house"
  }
  /**
   * 戸建かどうか
   */
  get isHouse() {
    return this.isHouseOrLand && !this.isLand
  }
  /**
   * 土地かどうか
   */
  get isLand() {
    return ["売地", "底地権", "借地権", "土地"].includes(
      this.propertyClassification
    )
  }
  /**
   * 成約物件かどうか
   */
  get isContract() {
    return this.dataType === "contract"
  }
  /**
   * 売出物件かどうか
   */
  get isSale() {
    return this.dataType === "sale"
  }
  /**
   * 賃貸物件かどうか
   */
  get isRent() {
    return this.dataType === "rent"
  }

  /**
   * @description
   * すべての物件が以下のいずれかの値をもつ。
   * - sold: 成約したことが確認できている売出物件。成約しても一定期間はサイト上に載せておく。
   * - old: 60日以上更新がなく、すでに成約している可能性がある売出物件。
   * - price_drop: 価格が更新された売出物件。
   * - sale: その他の売出物件。
   * - contract: 成約物件。
   * @returns {"sold" | "price_drop" | "old" | "sale" | "contract"}
   */
  getStatusType() {
    const { status, regPrice, dataType, relativeUpdatedAt } = this
    // status は
    // sale なら null | 0 | "" | "sold" | "price_drop"
    // contract なら undefined
    if (dataType === "sale") {
      if (status === "sold") {
        // status が sold の物件はすでに売れていることが確実な物件
        return "sold"
      } else if (relativeUpdatedAt > TOO_OLD_SALE_PROPERTY_DATE) {
        // old の物件は status が "price_drop" の場合があるので、 price_drop より優先して判定する
        return "old"
      } else if (status === "price_drop" && regPrice) {
        return "price_drop"
      } else {
        return "sale"
      }
    } else if (dataType === "contract") {
      return "contract"
    } else if (dataType === "rent") {
      return "rent"
    } else {
      throw new Error("unknown status")
    }
  }

  get constructionYearmonthText() {
    return this.constructionYearmonth
      ? this.constructionYearmonth.replace("/", "年") + "月"
      : "--"
  }

  get contractDateText() {
    return this.contractDate
      ? format(new Date(this.contractDate), "yyyy年MM月dd日")
      : "--"
  }

  get saleAddedOnText() {
    return this.saleAddedOn
      ? format(new Date(this.saleAddedOn), "yyyy年MM月dd日")
      : "--"
  }
}
