import _ from "lodash"
import { ApartmentImages } from "../entities/ApartmentImages"

export const getApartmentImagesList = ({ images }) => {
  const imagesByMasterId = _.groupBy(images, "masterId")
  return _.map(imagesByMasterId, (images, masterId) =>
    ApartmentImages.create({ images, masterId })
  )
}

export const getApartmentImagesByMasterId = ({ images }) => {
  const apartmentImagesList = getApartmentImagesList({ images })
  return _.reduce(
    apartmentImagesList,
    (result, a) => {
      result[a.masterId] = a
      return result
    },
    {}
  )
}
