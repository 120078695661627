import _ from "lodash"
import { beforeConfirmEmailSendFrom } from "./beforeFunctions/beforeConfirmEmailSendFrom"

// NOTE: const ROUTE_NAMES を作るべき？
const beforeEachSettings = {
  topConfirm: {
    pcName: "pc_top_confirm",
    spName: "sp_top_confirm",
  },
  topConsultationForm: {
    pcName: "pc_top_consultation_form",
    spName: "sp_top_consultation_form",
  },
  confirmEmailSendFrom: {
    pcName: "pc_confirm_email_send_from",
    spName: "sp_confirm_email_send_from",
    beforeFunction: beforeConfirmEmailSendFrom,
  },
  register: {
    pcName: "pc_lp_register",
    spName: "sp_register",
  },
  registerLogin: {
    pcName: "pc_lp_login",
    spName: "sp_lp",
  },
  registerVerify: {
    pcName: "pc_lp_register_verify",
    spName: "sp_register_verify",
  },
  registerCard: {
    pcName: "pc_lp_register_card",
    spName: "sp_register_card",
  },
  registerComplete: {
    pcName: "pc_lp_register_complete",
    spName: "sp_register_complete",
  },
  login: {
    pcName: "pc_lp_login",
    spName: "sp_login",
  },
  passwordResetMail: {
    pcName: "pc_lp_password_reset_mail",
    spName: "sp_password_reset_mail",
  },
  passwordReset: {
    pcName: "pc_lp_password_reset",
    spName: "sp_password_reset",
  },
}

const settingByName = _.reduce(
  beforeEachSettings,
  (result, value) => {
    const { pcName, spName } = value
    result[pcName] = value
    result[spName] = value
    return result
  },
  {}
)

export default settingByName
