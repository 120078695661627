import _ from "lodash"
import { isNumber } from "./format"
type AddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

type Address = {
  postCode: string
  city: string
  street: string
  buildingName: string
}

/**
 * 日本でAPIを実行した場合に返って来るタイプの一覧
 * 全てのケースはAPIドキュメントを参照
 * https://developers.google.com/maps/documentation/geocoding/requests-geocoding
 */
type AddressComponentType =
  | "postal_code" // 郵便番号
  | "country" // 国。「日本」が入っている
  | "political" // 政治的に定義された地理的地方であることを示します。
  | "administrative_area_level_1" // 都道府県（例：神奈川県、大阪府）
  | "locality" // 市町村（例：横浜市、長野市）
  | "sublocality" // 市町村以下のものに割り振られる。sublocality_level_xが一緒にくっついている
  // sub_locality_xは数が大きくなっていくとより細かい指定になる
  // 例：永田町1-2-3の場合、
  // level_1 ... 永田町
  // level_2 ... 1
  // level_3 ... 2
  // level_4 ... 3
  | "sublocality_level_1"
  | "sublocality_level_2"
  | "sublocality_level_3"
  | "sublocality_level_4"
  | "sublocality_level_5"
  // 普通は建物番号が入っている
  // しかし場合によっては番地が入っている
  // 同一レスポンスに複数のpremiseが入っていて、番地、建物番号と入っている場合もある
  | "premise"

class AddressComponentClass {
  constructor(private component: AddressComponent) {}

  get longName() {
    return this.component.long_name
  }

  hasType(type: string) {
    for (const typeOfComponent of this.component.types) {
      if (type === typeOfComponent) {
        return true
      }
    }
    return false
  }

  hasTypeMatches(regExp: RegExp) {
    for (const type of this.component.types) {
      if (regExp.test(type)) {
        return true
      }
    }
    return false
  }
}

export const parseAddressComponents = (
  addressComponents?: AddressComponent[] | undefined
): { location: string; address: Address } => {
  if (!addressComponents) {
    return {
      location: "",
      address: {
        postCode: "",
        city: "",
        street: "",
        buildingName: "",
      },
    }
  }
  // NOTE: 下記の2つだけ削除。あとは地番など細かい方から最後は都道府県まで並んでいるので、逆順にする
  // postal_code ... 郵便番号
  // country ... 国名

  // TODO: 建物名はpremiseに入っているので、そのうち取り出したい。
  //  ただし、premiseに地番が入っているケースもあるので注意が必要
  //  https://maps.multisoup.co.jp/blog/1952/
  const location = _.chain(addressComponents)
    .filter(
      (add) =>
        !add.types.includes("postal_code") && !add.types.includes("country")
    )
    .reverse()
    .map((add) => add.long_name || add.short_name)
    .value()
    .join("")
  const address = getAddress(addressComponents)

  return { location, address }
}

const getAddress = (addressComponents: AddressComponent[]) => {
  const address = {
    postCode: "",
    city: "",
    street: "",
    buildingName: "",
  }
  _.chain(addressComponents)
    // 大きい順に並べる
    .reverse()
    .each((component) => {
      const obj = new AddressComponentClass(component)
      if (obj.hasType("postal_code")) {
        address.postCode = obj.longName
      }
      if (obj.hasType("administrative_area_level_1")) {
        address.city = address.city + obj.longName
      }
      if (obj.hasType("locality")) {
        address.city = address.city + obj.longName
      }
      if (obj.hasType("sublocality")) {
        let connector = ""
        // 番地や丁目が単純な数で入っている場合は-でつなぐ
        if (isNumber(obj.longName)) {
          connector = "-"
        }
        address.street = address.street + connector + obj.longName
      }
      // premiseは建物名か番地かのどっちかが入っている
      if (obj.hasType("premise")) {
        if (isNumber(obj.longName)) {
          address.street = address.street + "-" + obj.longName
        } else {
          address.buildingName = obj.longName
        }
      }
    })
    .value()
  return address
}

const mapLongNamesByType = (addressComponents) => {}
