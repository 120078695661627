<template>
  <button
    :type="submit ? 'submit' : 'button'"
    :class="buttonClass"
    @click="onClick($event)"
    :disabled="disabled"
  >
    <span
      v-if="isProcessing"
      :class="['template__spinner', 'template__spinner--small']"
    />
    <template v-else>
      <span class="button__text"><slot /></span>
    </template>
  </button>
</template>

<script>
export default {
  emits: ["click", "input"],
  props: {
    /**
     * ボタンのテンプレート名
     */
    type: {
      validator(type) {
        return [
          "action",
          "ng-action",
          "cv-action",
          "cv-action-sub",
          "transition",
          "ng-transition",
          "in-cell-gray",
          "control",
          "theme-202303-tmp",
          "theme-202303-tmp-outlined",
          // 以下 v-model での使用を想定している type
          "select-polygon",
          "select-form",
          "select-tab",
          "select-nav",
        ].includes(type)
      },
    },
    /**
     * 指定すると true/false でトグル（ v-model 用）
     */
    value: {
      validator(value) {
        return [undefined, true, false].includes(value)
      },
    },
    /**
     * submit を発火させる
     */
    submit: {
      type: Boolean,
      default: false,
    },
    /**
     * true でスピナーを表示
     */
    isProcessing: {
      type: Boolean,
      required: false,
    },
    /**
     * size
     */
    large: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * width type
     */
    wide: {
      type: Boolean,
      default: true,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      const classes = ["button--common", this.type]
      if (this.value === true) {
        classes.push("selected")
      }
      const isFlexibleTemplate = [
        "action",
        "ng-action",
        "cv-action",
        "cv-action-sub",
        "transition",
        "ng-transition",
        "in-cell-gray",
      ].includes(this.type)
      if (isFlexibleTemplate) {
        classes.push("button__flexible-template")
        const size =
          (this.small && "small") ||
          (this.large && "large") ||
          (this.medium && "medium") // default: true
        const padding = (this.narrow && "narrow") || (this.wide && "wide") // default: true
        classes.push(`button__${size}`)
        classes.push(`button__${size}--${padding}`)
        if (this.fullWidth) {
          classes.push(`button__flexible-template--full-width`)
        }
      }

      return classes
    },
  },
  methods: {
    onClick(e) {
      if (this.isProcessing) {
        return
      }
      // v-model
      if (this.value !== undefined) {
        /**
         * v-model をつけた状態でボタンをクリックした時のイベント
         * @event input
         * @type {object}
         */
        this.$emit("input", !this.value)
      }
      /**
       * ボタンをクリックした時のイベント
       * @event click
       * @type {object}
       */
      this.$emit("click", e)
    },
  },
}
</script>

<style lang="scss" scoped>
.template__spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
}

.template__spinner--small {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
css module を使っているので、親コンポーネントから class を使用したスタイルの上書きをしてはいけない。
https://qiita.com/mascii/items/3202b9e18fd4a7366ac1#css-modules-%E3%81%AB%E3%82%82%E6%B0%97%E3%82%92%E3%81%A4%E3%81%91%E3%82%8B%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8%E3%81%8C%E3%81%82%E3%82%8B%E3%82%89%E3%81%97%E3%81%84

width と margin は決まっている type 以外はここでは指定せず、親コンポーネントから指定する

<TemplateButton type="action" class="button">配信する</TemplateButton>

.button {
  width: 180px;
  margin: 8px;
}
*/

// $bg-color, $hover-color, $shadow-color
@mixin button($colors) {
  border-radius: 4px;
  border-bottom-color: dark($colors);
  border-bottom-style: solid;
  background-color: regular($colors);
  &:hover {
    background-color: light($colors);
  }
}

.button--common {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &:disabled,
  &:disabled:hover {
    $_alpha: 0.5;
    color: rgba($color: $gray, $alpha: $_alpha);
    background-color: $grayish-white;
    // border-bottom-width はサイズによって違うので shorthand で上書きしないように
    border-right-width: 1px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-style: solid;
    border-color: $light-gray;
    cursor: default;
  }

  &:focus {
    outline-offset: 2px;
  }
}
.button__flexible-template {
  & > .button__text {
    display: inline-block;
    font-weight: bold;
    letter-spacing: 1.4px;
    text-indent: 1.4px;
    line-height: 1;
  }
  &.button__large {
    font-size: 22px;
    border-bottom-width: 4px;
    &--wide {
      padding: 14px 65px;
    }
    &--narrow {
      padding: 14px 25px;
    }
  }
  &.button__medium {
    font-size: 16px;
    border-bottom-width: 3px;
    &--wide {
      padding: 10px 40px;
    }
    &--narrow {
      padding: 10px 15px;
    }
  }
  &.button__small {
    font-size: var(--font-size);
    border-bottom-width: 2px;
    &--wide {
      padding: 10px 32px;
    }
    &--narrow {
      padding: 10px 12px;
    }
  }
  &--full-width {
    width: 100%;
  }
}

@mixin map-control() {
  margin: 8px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1.2px;
  @include shadow();
}

.action {
  @include button($blues);
  color: $white;
}
.ng-action {
  @include button($reds);
  color: $white;
}
.cv-action {
  @include button($oranges);
  color: $white;
}
.cv-action-sub {
  border-radius: 4px;
  border: 1px solid var(--light-orange);
  color: var(--light-orange);
  background: white;
  &:hover {
    color: var(--orange);
    border-color: var(--orange);
  }
}
.transition {
  @include button($blues);
  color: $white;
}
.ng-transition {
  @include button($reds);
  color: $white;
}
.control {
  @include map-control();
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: $textblack;
  background-color: $white;
}
.in-cell-gray {
  border: 1px solid $light-gray;
  @include button($grays);
  color: $dark-gray;
}
.select-polygon {
  @include map-control();
  height: 40px;
  width: 7em;
  border-radius: 20px;
  color: $textblack;
  background-color: $white;
  &.selected {
    color: $white;
    background-color: $blue;
  }
}
.select-form {
  padding: 16px;
  height: 36px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1.2px;
  color: $textblack;
  background-color: $snow;
  &.selected {
    color: $white;
    background-color: $blue;
  }
}

.select-tab {
  width: 155px;
  height: 45px;
  border: 1px solid $silver;
  font-size: 14px;
  color: $textblack;
  background-color: $snow;
  &.selected {
    background-color: $white;
    font-weight: bold;
  }
}
.select-nav {
  padding: 10px 5px;
  border-radius: 4px;
  letter-spacing: 0px;

  color: $dark-gray;
  border: 2px solid transparent;
  background-color: $grayish-white;
  &.selected {
    color: $blue;
    border: 2px solid $blue;
    background-color: $white;
    outline: inherit;
  }
}

.theme-202303-tmp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--template-button-color, var(--dark-gray));
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 999px;
  border-bottom-width: 0;
  padding: 12px;
  width: 100%;
  outline: none;
}

.theme-202303-tmp-outlined {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--template-button-color, var(--dark-gray));
  color: var(--template-button-color, var(--dark-gray));
  font-size: 14px;
  font-weight: bold;
  border-radius: 999px;
  padding: 12px;
  width: 100%;
  outline: none;
}
</style>
