/*
  顧客へ独自メールを送信する際のデータ関連
 */
import { postClientsEmail } from "@/helpers/api"
import { MAIL_TYPES } from "@share/const"
import { getClientMailSampleIframeUrl } from "@share/url"
import _ from "lodash"

const initialClientEmailState = {
  // FIXME: tmp
  // mailType: MAIL_TYPES.AMAZON_GIFT_BUY,
  mailType: MAIL_TYPES.CLIENT_ORIGINAL,
  urlKeys: "",
  originalMail: {
    subject: "",
    content: "",
  },
}

export default {
  namespaced: true,
  state: _.cloneDeep(initialClientEmailState),
  getters: {
    mailSampleIframeUrl: (state, getters, rootState) => {
      return getClientMailSampleIframeUrl({
        domain: window.location.origin,
        mailType: state.mailType,
        userId: rootState.registerForm.userId,
      })
    },
  },
  mutations: {
    updateMailType(state, { mailType }) {
      state.mailType = mailType
    },
    updateOriginalMailSubject(state, { subject }) {
      state.originalMail.subject = subject
    },
    updateOriginalMailContent(state, { content }) {
      const prependContent = "<p><%= client.clientNameAtMail %></p>"
      state.originalMail.content = prependContent + content
    },
  },
  actions: {
    updateMailType(context, data) {
      context.commit("updateMailType", data)
    },
    updateOriginalMailSubject(context, data) {
      context.commit("updateOriginalMailSubject", data)
    },
    updateOriginalMailContent(context, data) {
      context.commit("updateOriginalMailContent", data)
    },
    async submitClientEmail({ state, rootState, dispatch }) {
      const ids = _.map(rootState.listClient.selectedClients, "id")
      const { originalMail, mailType } = state
      const { subject, content } = originalMail
      const { isAllSelected, serverParams } = rootState.listClient
      await postClientsEmail({
        subject,
        content,
        ids,
        mailType,
        serverParams: isAllSelected ? serverParams : undefined,
        prependClientName: true,
      })
      await dispatch("listClient/watchQueueProgress", null, { root: true })
    },
  },
}
