import * as api from "@/helpers/api"
import { getApartmentImagesByMasterId } from "@share/entityServices/ApartmentImagesService"
import { getImagesByPropertyId } from "@share/interfaceAdapters/ApartmentImagesPresenter"
import _ from "lodash"
export const defaultImageUrl = "/assets/images/apartment_default.svg"
export const defaultImages = [
  {
    type: "no-image",
    url: defaultImageUrl,
  },
]

const imagesCache = new WeakMap()

// filteredProperties が更新されたら実行する
export const fetchApartmentImagesInCache = async (properties) => {
  const masterIds = (() => {
    const set = properties.reduce((set, p) => {
      if (p.masterId && !imagesCache.has(p)) {
        set.add(p.masterId)
      }
      return set
    }, new Set())
    return Array.from(set)
  })()
  if (masterIds.length === 0) return

  return makeChunks(masterIds)(async (chunk) => {
    const images = await api.getImages({ masterIds: chunk })
    const apartmentImagesByMasterId = getApartmentImagesByMasterId({ images })
    const imagesByPropertyIds = getImagesByPropertyId({
      properties,
      apartmentImagesByMasterId,
    })
    _.each(properties, (p) => {
      const { propertyId } = p
      const imagesByType = imagesByPropertyIds[propertyId]
      if (imagesByType) {
        imagesCache.set(p, imagesByType)
      }
    })
  })
}

// Cache には直接アクセスしない
export const getAllImagesOfProperty = (
  property,
  { group, types /* "exterior" | "room" */ } = { group: "exterior", types: "" }
) => {
  if (property.propertyType === "house") {
    return getHouseImages(property)
  } else {
    const cache = imagesCache.get(property)
    if (!cache) {
      return defaultImages
    }
    return types ? sortByType(cache, types) : sortByGroup(cache, group)
  }
}

const sortByType = (imagesByGroup, types) => {
  const { exterior, room, building } = imagesByGroup
  const beforeSort = [...exterior, ...room, ...building]
  return _.reduce(
    types,
    (result, type) => {
      const filteredByType = _.filter(
        beforeSort,
        (image) => image.type === type
      )
      return result.concat(filteredByType)
    },
    []
  )
}

const sortByGroup = (imagesByGroup, group) => {
  const { exterior, room, building } = imagesByGroup
  switch (group) {
    case "exterior": // 外観画像を優先
      return [...exterior, ...room, ...building]
    case "room": // 室内画像を優先
      return [...room, ...exterior, ...building]
    default:
      throw new Error(`Invalid sort group: ${group}`)
  }
}

const isValidImageUrl = (url) => {
  return (
    typeof url === "string" &&
    url !== defaultImageUrl &&
    // streetviewstaticXXXXXXXX_YYYYYYY.jpg のような文字列が入っている場合がある（ imgUrl2 のみ？）
    url.match(/^https?:/)
  )
}

const filterValidImages = (images) => {
  return images.filter((image) => isValidImageUrl(image.url))
}

/**
 * @typedef {object} Image
 * @property {string} key
 * @property {string} url
 */
/**
 * Property がもつ画像を { key, url } というオブジェクトの配列としてすべて取得する
 * @param {object} property
 * @return {Image[]}
 */
const getHouseImages = (property) => {
  const imageUrls = property.imageUrls
  const images = filterValidImages(
    imageUrls.map((url) => ({
      url,
    }))
  )
  return images.length > 0 ? images : defaultImages
}

const makeChunks =
  (array, size = 500) =>
  (task) => {
    const chunks = []
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size))
    }
    return Promise.all(chunks.map(task))
  }
