import { b64urlDecode, b64urlEncode } from "@waiting/base64"
import _ from "lodash"

export const encodeObject = (obj) => {
  if (_.isEmpty(obj)) {
    return
  }
  const str = JSON.stringify(obj)
  return b64urlEncode(str)
}
export const decodeObject = (b64Text) => {
  if (_.isEmpty(b64Text)) {
    return
  }
  const decodedStr = b64urlDecode(b64Text)
  return JSON.parse(decodedStr)
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let encoded = reader.result?.toString().replace(/^data:(.*,)?/, "") || ""
      resolve(encoded)
    }
    reader.onerror = reject
  })
