import {
  getClients,
  getUserMailDeliveryLimit,
  getUserQueueProgresses,
} from "@/helpers/api"
import { Client } from "@share/entities/Client/Client"
import { createClientListQuery } from "@share/entities/ClientListQuery"
import _ from "lodash"

/**
 * 管理画面の顧客リストのデータ関連
 * チェックボックスの選択状況も含む
 */
export default {
  namespaced: true,
  state: createListClientState(),
  getters: {
    isQueueInProgress: (state) => {
      return !!state.queueProgress.queueName
    },
    isOverLimit: (state) => {
      return state.deliveryLimit <= state.deliveryCount
    },
    // hasData: (state) => {
    //   if (state.isLoading || !state.initialLoadFinished) {
    //     return true
    //   }
    //   if (!_.isEmpty(state.serverParams.columnFilters)) {
    //     return true
    //   }
    //   if (state.totalRecords !== 0) {
    //     return true
    //   }
    //   // 担当者の場合は、自分が担当している顧客がいなくても deliveryCount が存在していれば OK
    //   if (state.deliveryCount) {
    //     return true
    //   }
    //   return false
    // },
    selectedClientsCount: (state) => {
      if (state.isAllSelected) {
        return state.totalRecords
      }
      return state.selectedClients.length
    },
  },
  mutations: {
    updateDeliveryCount(state, { deliveryCount, deliveryLimit }) {
      state.deliveryCount = deliveryCount
      state.deliveryLimit = deliveryLimit
    },
    updateClients(
      state,
      { deliveryCount, totalRecords, deliveryLimit, clients }
    ) {
      state.deliveryLimit = deliveryLimit
      state.deliveryCount = deliveryCount
      state.clients = clients
      state.totalRecords = totalRecords
      //
      state.initialLoadFinished = true
    },
    updateSelectedClients(state, { selectedClients }) {
      state.selectedClients = selectedClients
    },
    toggleIsAllSelected(state, { value }) {
      state.isAllSelected = !!value
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    updateServerParams(state, { serverParams }) {
      state.serverParams = Object.assign({}, state.serverParams, serverParams)
    },
    initializeQueueProgress(state) {
      state.queueProgress = createQueueProgress()
    },
    updateQueueProgress(state, { queueProgress }) {
      state.queueProgress = queueProgress
    },
  },
  actions: {
    updateDeliveryCount(context, data) {
      context.commit("updateDeliveryCount", data)
    },
    updateServerParams(context, data) {
      context.commit("updateServerParams", data)
    },
    updatedIsAllSelected(context, data) {
      context.commit("toggleIsAllSelected", data)
    },
    updateSelectedClients(context, data) {
      context.commit("updateSelectedClients", data)
    },
    async reloadClients(context) {
      context.commit("setIsLoading", true)
      const sanitizedServerParams = sanitizeServerParams(
        context.state.serverParams
      )
      const { totalRecords, clients, deliveryCount } = await getClients(
        sanitizedServerParams
      )
      const { deliveryLimit } = await getUserMailDeliveryLimit()

      context.commit("updateClients", {
        deliveryCount,
        totalRecords,
        deliveryLimit,
        clients,
      })
      context.commit("setIsLoading", false)
    },
    async watchQueueProgress(context) {
      const progresses = await getUserQueueProgresses()
      if (_.isEmpty(progresses)) {
        if (this.isQueueInProgress) {
          await this.reloadClients()
        }
        context.commit("initializeQueueProgress")
      } else {
        context.commit("updateQueueProgress", { queueProgress: progresses[0] })
        return (this.watchQueueProgressTimer = setTimeout(() => {
          context.dispatch("watchQueueProgress")
        }, 1000))
      }
    },
  },
}

function createListClientState() {
  return {
    isAllSelected: false,
    isLoading: false,
    serverParams: createClientListQuery(),
    selectedClients: [],
    clients: [],
    totalRecords: 0,
    deliveryCount: 1,
    deliveryLimit: 0,
    queueProgress: createQueueProgress(),
    initialLoadFinished: false,
  }
}
function createQueueProgress() {
  return {
    queueName: "",
    queueLabel: "",
    progress: {
      total: 0,
      processedNum: 0,
    },
  }
}

/**
 * 顧客テーブルのカラム名にgetterを利用したことにより、本来のカラム名と差分が出てしまった
 * 例えば、createdAt -> createdAtJstなど。
 * その差分をここで吸収しておく
 */
function sanitizeServerParams(serverParams) {
  const sanitized = _.cloneDeep(serverParams)
  sanitized.columnFilters = _.reduce(
    sanitized.columnFilters,
    (result, value, key) => {
      result[Client.originalColumnName(key)] = value
      return result
    },
    {}
  )
  sanitized.sort = sanitized.sort.map((option) => {
    const { field, type } = option
    return { field: Client.originalColumnName(field), type }
  })
  return sanitized
}
