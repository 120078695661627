// import _ from "lodash";

export class Base {
  static create(arg) {
    return new this(arg)
  }
  toJSON() {
    const jsonObj = {}
    for (const key in this) {
      if (typeof this[key] !== "function") {
        jsonObj[key] = this[key]
      }
    }
    return jsonObj
  }
}
